import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import gsap, { Power4 } from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import {
  Drawer as MuiDrawer,
  List as MuiList,
  ListItemButton as MuiListItemButton,
  ListItemText as MuiListItemText
} from '@mui/material'
import withContext from '@/context/withContext'
import ThemeContext from '@/context/Theme'
import { rem, mq } from '@/utils/helpers'
import breakpoints from '@/utils/breakpoints'
import layouts from '@/utils/layouts'
import zIndex from '@/utils/z-index'
import CSSEase from '@/utils/easing'
import { Theme, color } from '@/utils/themes'
import _Container from '@/components/layouts/Container'
import Typography from '@/components/typography'
import ServiceLogo from '@/components/snippets/ServiceLogo'
import Button from '@/components/snippets/Button'

gsap.registerPlugin(ScrollToPlugin)

interface Props {
  [key: string]: any
}

interface State {
  open: boolean
  show: boolean
}

interface StyleProps {
  theme?: Theme
  [key: string]: any
}

interface RootStyleProps extends StyleProps {
  show: boolean
}

class Header extends React.Component<Props, State> {
  private rootRef = React.createRef<HTMLInputElement>()
  private observer: IntersectionObserver | undefined

  constructor(props: Props) {
    super(props)
    const state = {
      open: false,
      show: false
    }
    this.state = state
  }

  componentDidMount() {
    this.connectObserver()
  }

  componentWillUnmount() {
    this.disconnectObserver()
  }

  connectObserver() {
    this.observer = new window.IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        root: null,
        rootMargin: '0px 0px',
        threshold: 0
      }
    )
    const kv = document.getElementById('kv')
    if (!kv) return
    this.observer.observe(kv)
  }

  disconnectObserver() {
    this.observer?.disconnect()
    this.observer = undefined
  }

  handleIntersection(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.setState({
          show: false
        })
      } else {
        this.setState({
          show: true
        })
      }
    })
  }

  toggleDrawer = () => {
    this.setState({
      open: !this.state.open
    })
  }

  closeDrawer = () => {
    this.setState({
      open: false
    })
  }

  onLinkClick(event: React.MouseEvent, path: string) {
    this.closeDrawer()
    if (path.indexOf('#') === -1) {
      return
    }
    event.preventDefault()
    const anchor = event.currentTarget as HTMLAnchorElement
    const id = anchor.hash
    if (!document.querySelector(id)) {
      return
    }
    const headerHeight = this.rootRef.current?.clientHeight
    gsap.to(window, {
      duration: 0.6,
      ease: Power4.easeInOut,
      scrollTo: {
        y: id,
        offsetY: headerHeight
      }
    })
  }

  render() {
    const { themeContext, ...others } = this.props
    return (
      <StaticQuery
        query={query}
        render={({ nav }) => {
          const main = nav.edges[0].node.main
          const cta = nav.edges[0].node.cta
          return (
            <Root
              {...others}
              theme={themeContext.theme}
              ref={this.rootRef}
              show={this.state.show}
              id="header"
            >
              <Container type={1}>
                <Left>
                  <Logo
                    to="/#top"
                    onClick={(e: React.MouseEvent) =>
                      this.onLinkClick(e, '/#top')
                    }
                  >
                    <ServiceLogo />
                  </Logo>
                </Left>
                <Right>
                  <Nav>
                    {main.map((item: any, index: number) => {
                      return (
                        <Nav_Item key={index}>
                          <Nav_Link
                            variant="body2"
                            component={Link}
                            to={item.path}
                            theme={this.props.themeContext.theme}
                            onClick={(e: React.MouseEvent) =>
                              this.onLinkClick(e, item.path)
                            }
                          >
                            {item.title}
                          </Nav_Link>
                        </Nav_Item>
                      )
                    })}
                  </Nav>
                  <CTA
                    width="fit"
                    size="small"
                    iconBefore="chat"
                    to={cta.path}
                    onClick={(e: React.MouseEvent) =>
                      this.onLinkClick(e, cta.path)
                    }
                  >
                    {cta.title}
                  </CTA>
                  <MenuButton
                    onClick={this.toggleDrawer}
                    open={this.state.open}
                  >
                    <MenuButton_Container>
                      <MenuButton_Open theme={this.props.themeContext.theme}>
                        <div></div>
                        <div></div>
                        <div></div>
                      </MenuButton_Open>
                    </MenuButton_Container>
                  </MenuButton>
                  <Drawer
                    anchor="right"
                    open={this.state.open}
                    onClose={this.closeDrawer}
                  >
                    <List>
                      {main.map((item: any, index: number) => {
                        return (
                          <ListItemButton
                            key={index}
                            component={Link}
                            to={item.path}
                            onClick={(e: React.MouseEvent) =>
                              this.onLinkClick(e, item.path)
                            }
                            theme={this.props.themeContext.theme}
                          >
                            <ListItemText>
                              <Typography variant="subtitle2">
                                {item.title}
                              </Typography>
                            </ListItemText>
                          </ListItemButton>
                        )
                      })}
                    </List>
                    <Drawer_CTA>
                      <Button
                        width="fill"
                        size="small"
                        iconBefore="chat"
                        to={cta.path}
                        onClick={(e: React.MouseEvent) =>
                          this.onLinkClick(e, cta.path)
                        }
                      >
                        {cta.title}
                      </Button>
                    </Drawer_CTA>
                  </Drawer>
                </Right>
              </Container>
            </Root>
          )
        }}
      />
    )
  }
}

const query = graphql`
  query HeaderQuery {
    nav: allMenuYaml {
      edges {
        node {
          main {
            title
            path
          }
          cta {
            title
            path
          }
        }
      }
    }
  }
`

export default withContext('themeContext', ThemeContext, Header)

const Root = styled.div<RootStyleProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header.base};
  background-color: ${color('black.0')};
  border-bottom: 1px solid ${color('black.5')};
  transition: transform 0.3s ${CSSEase.easeInOutExpo},
    opacity 0.3s ${CSSEase.easeInOutExpo};
  opacity: 0;
  transform: translateY(-100%);
  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      transform: translateY(0);
    `};
`

const CTA = styled(Button)`
  margin-left: ${rem(24)};
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    display: none;
  }
`

const Container = styled(_Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${rem(76)};
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    height: ${rem(56)};
  }
`

const Left = styled.div``

const Right = styled.div`
  display: flex;
  align-items: center;
`

const Logo = styled(Link)`
  display: block;
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    svg {
      width: ${rem(95.2)};
      height: ${rem(32)};
    }
  }
`

const Nav = styled.div`
  display: flex;
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    display: none;
  }
`

const Nav_Item = styled.div`
  margin-left: ${rem(24)};

  @media ${mq.with(breakpoints.lg)} {
    margin-left: ${rem(24)};
  }

  &:first-of-type {
    margin-left: 0;
  }
`

const Nav_Link = styled(Typography)<StyleProps>`
  display: block;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    right: 0;
    display: block;
    height: 4px;
    background-color: ${color('primary')};
    transition: transform 0.1s ${CSSEase.easeOtt};
    transform: scaleY(0);
    transform-origin: center bottom;
  }

  &:hover::before {
    transform: scaleY(1);
    @media (hover: none) {
      transform: scaleY(0);
    }
  }
`

const MenuButton = styled.div<StyleProps>`
  display: none;
  z-index: ${zIndex.header.base};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @media ${mq.and(breakpoints.md, breakpoints.sm)} {
    display: block;
  }
  @media ${mq.with(breakpoints.md)} {
    right: ${layouts.pad.md};
  }
  @media ${mq.with(breakpoints.sm)} {
    right: ${layouts.pad.sm};
  }
  width: ${rem(40)} !important;
  height: ${rem(40)} !important;
  border-radius: ${rem(5)} !important;
  padding: 0 !important;
  min-width: auto !important;
`

const MenuButton_Container = styled.div`
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${rem(24)};
  height: ${rem(16)};

  > * {
    width: 100%;
    height: 100%;
  }
`

const MenuButton_Open = styled.div<StyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  > div {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    border-bottom: ${(props) => `2px solid ${props.theme.primary[100]}`};

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: 50%;
    }

    &:nth-of-type(3) {
      top: 100%;
    }
  }
`

const Drawer = styled(MuiDrawer)``

const List = styled(MuiList)`
  width: ${rem(240)};
`.withComponent('nav')

const ListItemButton = styled(MuiListItemButton)<StyleProps>`
  border-bottom: ${(props) => `1px solid ${props.theme.black[10]}`};
`

const ListItemText = styled(MuiListItemText)``

const Drawer_CTA = styled.div`
  margin-top: ${rem(16)};
  padding: 0 ${rem(16)};
`
